<template>
  <div class="detail_page">
    <my-header isShowLogo="show" :logoSrc="logo"></my-header>
    <div class="tips">Double click to enter or exit full screen mode</div>
    <!-- 第四步，给滚动容器dom上加上data-simplebar属性做标记为滚动容器，data-simplebar-auto-hide="true"设置自动隐藏 -->
    <div class="scrollBarWrap" data-simplebar data-simplebar-auto-hide="false">
      <simple-bar>
        <figure>
          <img :src="contentImg" alt="" />
        </figure>
      </simple-bar>
    </div>
  </div>
</template>

<script>
import logo from "../assets/images/product/logo.png";
import MyHeader from "../components/MyHeader.vue";
export default {
  components: {
    MyHeader,
  },
  props: {},
  data() {
    return {
      contentImg: "",
      logo,
    };
  },
  async created() {
    const query = JSON.parse(JSON.stringify(this.$route.query));
    this.contentImg = query.content;
  },
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
<style lang="scss">
@import "../assets/scss/_fun.scss";
.detail_page {
  position: relative;
  width: 100vw;
  height: auto;
  top: 0;
  left: 0;
  .tips {
    position: fixed;
    top: vw(80);
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 16px !important;
    font-family: Helvetica;
    opacity: 0.5;
    z-index: 9;
  }
  .scrollBarWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .simplebar-scrollbar {
      width: vw(10);
      height: vw(100);
      background: rgba($color: #ffffff, $alpha: 0.8);
      border-radius: vw(14);
      backdrop-filter: blur(10px);
      transition: height 1s ease;
      &:before {
        display: none;
      }
    }
    .simplebar-track {
      position: absolute;
      top: 25%;
      right: vw(40);
      width: vw(11);
      height: vw(583);
      background: rgba($color: #000000, $alpha: 0.65);
      border-radius: vw(14);
      border: 1px solid #979797;
      backdrop-filter: blur(10px);
    }
  }
}
</style>
