<template>
  <div :class="[page === 'video' ? 'header_vid' : 'header_pro', 'header_wrap']">
    <div class="back_btn" @click="goBackEvent">
      <figure>
        <img src="../assets/images/back.png" alt="" />
      </figure>
      <p>BACK</p>
    </div>
    <figure  :class="[page === 'video' ? 'logo_vid' : 'logo_pro']" v-if="isShowLogo == 'show'">
      <img :src="logoSrc" alt="" />
    </figure>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    isShowLogo: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    logoSrc: {
      type: String,
      default: "",
    },
    page: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    goBackEvent() {
      if (this.path) {
        this.$router.push(`/${this.path}`);
      } else {
        this.$router.go(-1);
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/_fun.scss";
.header_wrap {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  &.header_pro {
    width: vw(1718);
    margin-left: vw(97);
    padding-top: vw(85);
  }
  &.header_vid {
    width: vw(1800);
    margin-left: 0;
    padding-top: vw(50);
  }
  .back_btn {
    width: vw(200);
    height: vw(70);
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 vw(36);
    z-index: 99;
    cursor: pointer;
    > p {
      line-height: normal;
      font-size: rem(32);
      font-weight: 500;
      color: #fff;
      font-family: Helvetica;
    }
    > figure {
      width: vw(19);
    }
  }
  .logo_pro {
    width: vw(504);
    height: vw(54);
  }
  .logo_vid {
    width: vw(443);
    height: vw(56);
  }
}
</style>
